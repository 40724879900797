<template>
  <div class="Profile">
    <div v-if="!$erdAccount.address">
      <VueErdjsConnect />
    </div>
    <div v-else>
      <h2 class="page-title">{{ $erdAccount.obfuscatedAddress() }}</h2>

      <!-- <div v-if="false">
        <div>X.XX EGLD</div>
        <button @click="getUserBalance()">Refresh Balance</button>
      </div> -->

      <div class="profile-content">
        <ul>
          <li
            class="button_hover_1"
            :class="{ activeSection: activeSection == sections.collections }"
            @click="goToSection(sections.collections)"
          >
            collections
          </li>
          <li
            class="button_hover_1"
            :class="{ activeSection: activeSection == sections.nfts }"
            @click="goToSection(sections.nfts)"
          >
            NFTs
          </li>
          <li
            class="button_hover_1"
            :class="{ activeSection: activeSection == sections.drops }"
            @click="goToSection(sections.drops)"
          >
            drops
          </li>
          <!-- <li
            :class="{ activeSection: activeSection == 2 }"
            @click="activeSection = 2"
          >
            coming soon
          </li> -->
        </ul>
        <div>
          <div v-if="activeSection === sections.collections">
            <div v-if="collections">collections...</div>
            <div v-else class="not-found">
              <p class="transparent">no collections found &#128542;</p>
              <div class="create-wrapper button_hover_1">
                <font-awesome-icon
                  icon="plus"
                  alt="create drop"
                  @click="createCollection()"
                />
              </div>
            </div>
          </div>
          <div v-else-if="activeSection === sections.nfts">
            <div v-if="nfts">nfts...</div>
            <div v-else class="not-found">
              <p class="transparent">no NFTs found &#128542;</p>
              <div class="create-wrapper button_hover_1">
                <font-awesome-icon
                  icon="plus"
                  alt="create drop"
                  @click="createNft()"
                />
              </div>
            </div>
          </div>
          <div v-else-if="activeSection === sections.drops">
            <div v-if="drops">drops...</div>
            <div v-else class="not-found">
              <p class="transparent">no drops found &#128542;</p>
              <div class="create-wrapper button_hover_1">
                <font-awesome-icon
                  icon="plus"
                  alt="create drop"
                  @click="$router.push({ path: '/create-drop' })"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="logout" title="logout" style="margin-top: 200px">
        <font-awesome-icon
          icon="sign-out-alt"
          size="2x"
          alt="logout"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignOutAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
library.add(faSignOutAlt, faPlus);

export default {
  name: "Auth",
  components: {},
  data() {
    return {
      sections: {
        collections: 0,
        nfts: 1,
        drops: 2,
      },
      activeSection: 1,
    };
  },
  mounted() {
    // fcking fck :)
    //this.activeSection = localStorage.getItem("profileActiveSection");
    // custom redirect try...
    // const redirect = this.$route.query.to;
    // const isLogged =
    //   this.$erdAccount.obfuscatedAddress() ?? this.$erdAccount.address;
    // console.log(`redirect ${redirect} && isLogged ${isLogged}`);
    // if (redirect && isLogged) {
    //   console.log(`redirect to ${redirect}`);
    //   this.$router.push(`${redirect}`);
    // }
  },
  computed: {
    ...mapState({
      collections: (state) => state.collections,
      nfts: (state) => state.nfts,
      drops: (state) => state.drops,
    }),
  },
  methods: {
    logout() {
      this.$erd.logout();
      this.$router.push("/");
    },
    goToSection(idx) {
      this.activeSection = idx;
      localStorage.setItem("profileActiveSection", idx);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  transform: translateY(10%);
}
/* Firebase UI Dark Theme */
#firebaseui-auth-container {
  background-color: transparent;
  --background-color: rgba(18, 18, 18, 0.5);
  color: white;
  -webkit-transition: background 0.88s ease-in-out;
  transition: background 0.88s ease-in-out;
}

#firebaseui-auth-container.light {
  background-color: transparent;
  --background-color: rgba(255, 255, 255, 0.5);
  color: #121212;
  -webkit-transition: background 0.88s ease-in-out;
  transition: background 0.88s ease-in-out;
}

.firebaseui-card-content {
  background-color: transparent;
}

.profile-content {
  display: flex !important;
  flex-direction: column;
  flex-basis: auto;
  align-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;

  & ul {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    border-radius: 4px;

    & li {
      display: flex;
      align-content: center;
      align-items: center;
      font-size: 20px;
      background: var(--theme-color-1);

      margin: 0 7.5px;
      padding: 7.5px 10px;
      border-radius: 25px;
    }
  }

  & div {
    margin-top: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

.activeSection {
  background: var(--theme-color-3) !important;
}

.not-found {
  display: flex;
  flex-direction: column;
}
.transparent {
  opacity: 0.5;
}

.create-wrapper {
  margin-top: 50px;
  padding: 10px;
  background: var(--theme-color-1);
  border-radius: 50%;
  width: 45px;
  height: 45px;
}
</style>
