import { createStore } from "vuex";
import drops from "./drops";

export default createStore({
  state: {
    theme: "light", // '' or 'light'
    innerWidth: undefined,
    innerHeight: undefined,
    // X
    collections: undefined,
    nfts: undefined,
    drops: undefined,
  },
  getters: {},
  mutations: {
    TOGGLE_THEME(state) {
      if (!state.theme) {
        state.theme = "light";
        localStorage.setItem("theme", "light");
      } else {
        state.theme = "";
        localStorage.setItem("theme", "");
      }
    },
    SET_SIZE(state, param): void {
      state.innerWidth = param.innerWidth;
      state.innerHeight = param.innerHeight;
    },
  },
  actions: {
    ...drops.actions,
    async init({ state }) {
      state.theme = localStorage.getItem("theme") ?? state.theme;
    },
    async updateScreenSize({ commit }, params) {
      commit("SET_SIZE", params);
    },
    async getDummyNfts() {
      return [
        {
          identifier: "MOS-b9b4b2-2710",
          collection: "MOS-b9b4b2",
          timestamp: 1638704328,
          attributes:
            "dGFnczpTdHJhbW9zaSxSb21hbmlhLEVscm9uZCxSb21hbmlhREFPO21ldGFkYXRhOlFtVVVoQW1CUUtHa1NxTjc3NU5aQUFZVWFxZDhzc01hZEZnMlVZU0VDU0VSejYvOTE0Lmpzb24=",
          nonce: 10000,
          type: "NonFungibleESDT",
          name: "Stramosi #10000",
          creator:
            "erd1qqqqqqqqqqqqqpgq0p9k56lutyjsz288gsrtu64nfj43ll8vys5sjy7luv",
          royalties: 10,
          uris: [
            "aHR0cHM6Ly9pcGZzLmlvL2lwZnMvUW1VVWhBbUJRS0drU3FONzc1TlpBQVlVYXFkOHNzTWFkRmcyVVlTRUNTRVJ6Ni85MTQucG5n",
            "aHR0cHM6Ly9pcGZzLmlvL2lwZnMvUW1VVWhBbUJRS0drU3FONzc1TlpBQVlVYXFkOHNzTWFkRmcyVVlTRUNTRVJ6Ni85MTQuanNvbg==",
            "aHR0cHM6Ly9pcGZzLmlvL2lwZnMvUW1VVWhBbUJRS0drU3FONzc1TlpBQVlVYXFkOHNzTWFkRmcyVVlTRUNTRVJ6Ni9jb2xsZWN0aW9uLmpzb24=",
          ],
          url: "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/914.png",
          media: [
            {
              url: "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/914.png",
              fileSize: 163481,
              fileType: "image/png",
              originalUrl:
                "https://ipfs.io/ipfs/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/914.png",
              thumbnailUrl:
                "https://media.elrond.com/nfts/thumbnail/MOS-b9b4b2-947a3912",
            },
          ],
          isWhitelistedStorage: true,
          tags: ["stramosi", "romania", "elrond", "romaniadao"],
          metadata: {
            dna: "f74f827a8d380ed4330b432441acd9981b6d9cf7",
            rarity: {
              avgRarity: 0.033108,
              statRarity: 3.369807398e-22,
              rarityScore: 966.992372,
              usedTraitsCount: 13,
              rarityScoreNormed: 3908.022428,
            },
            compiler: "Stramosi",
            attributes: [
              { value: "Rimetea", trait_type: "Background" },
              { value: "Vuia_Noah", trait_type: "Bust" },
              { value: "Creanga_Koriol", trait_type: "Hair" },
              { value: "Lipatti_Kereth", trait_type: "Ears" },
              { value: "Rautu_Milser", trait_type: "Face" },
              { value: "Barbu_Lalsail", trait_type: "Beard" },
              { value: "Stirbey_Iniomo", trait_type: "Moustache" },
              { value: "Raducanu_Siollir", trait_type: "Mouth" },
              { value: "Pacea_Colil", trait_type: "Eyes" },
              { value: "Palade_Lemiond", trait_type: "Nose" },
              { value: "Vianu_Mulun", trait_type: "Eyebrows" },
              { value: "Irimescu_Thetol", trait_type: "Bangs" },
              { value: "Davidoglu_Uonmas", trait_type: "Hat" },
            ],
            description:
              "A collection of wacky, unique characters that are inspired by the vibrant folklore of Romania",
          },
          owner:
            "erd15gculjmu3r62ldlwyguqdgddez35r2lv6ka8j7s6pwhqlc80httqljzwgm",
          supply: "1",
          ticker: "MOS",
          score: 58.725,
          rank: 3215,
          isNsfw: false,
          isTransferAffected: true,
          assets: {
            website: "https://www.stramosi.com/",
            description:
              "A collection of wacky, unique characters that are inspired by the vibrant folklore of Romania",
            social: {
              email: "contact@stramosi.com",
              twitter: "https://twitter.com/stramosinft",
              discord: "https://discord.gg/GNDYWqfPPr",
              telegram: "https://t.me/stramosiro",
            },
            status: "active",
            pngUrl: "https://media.elrond.com/tokens/asset/MOS-b9b4b2/logo.png",
            svgUrl: "https://media.elrond.com/tokens/asset/MOS-b9b4b2/logo.svg",
          },
        },
      ];
    },
  },
  // modules: {
  //   drops: drops,
  // },
});
