<template>
  <div id="navbar">
    <div class="left" v-show="!isSearchActive">
      <img
        v-show="this.theme == ''"
        src="../assets/egldart.png"
        @click="$router.push({ path: '/' })"
        id="logo"
        title="egld.art logo"
      />
      <img
        v-show="this.theme == 'light'"
        src="../assets/egldart.png"
        @click="$router.push({ path: '/' })"
        id="logo"
        title="egld.art logo"
      />
      <div id="app_name">egld.art</div>
    </div>

    <div class="right">
      <div id="menu_search" v-if="false">
        <font-awesome-icon
          :icon="['fas', 'xmark']"
          id="menu_search_x"
          class="button_hover"
          v-show="isSearchActive"
          @click="this.isSearchActive = false"
        />
        <input
          v-if="isSearchActive"
          id="menu_search_input"
          type="text"
          placeholder="search NFTs, collections or accounts"
        />
        <font-awesome-icon
          :icon="['fas', 'magnifying-glass']"
          size="xs"
          id="menu_search_icon"
          class="button_hover"
          @click="search()"
        />
      </div>

      <div id="dropdown" v-show="!isSearchActive && false">
        <font-awesome-icon
          :icon="['fas', 'caret-down']"
          id="dropdown_button"
          class="button_hover"
        />
        <div id="dropdown_content">
          <a @click="$router.push({ path: '/' })">gallery</a>
          <a @click="$router.push({ path: '/drops' })">drops</a>
          <a @click="$router.push({ path: '/about' })">about</a>
          <!-- <a @click="$router.push({ path: '/profile' })">
            {{
              $erdAccount.address
                ? toShortAddress($erdAccount.obfuscatedAddress())
                : "login"
            }}
          </a> -->
        </div>
      </div>

      <!-- <VMenu v-show="!isSearchActive" id="menu_dropdown" :distance="6">
        <font-awesome-icon :icon="['fas', 'caret-down']" class="button_hover" />
        <template #popper>
          <div id="menu_dropdown_items_wrapper">
            <div @click="$router.push({ path: '/' })">gallery</div>
            <div @click="$router.push({ path: '/about' })">about</div>
          </div>
        </template>
      </VMenu> -->

      <div v-show="!isSearchActive && false" class="button_hover">
        <!-- <router-link class="menu__items-link" to="/authenticate"> -->
        <div id="wallet" @click="$router.push({ path: '/profile' })">
          <div
            v-if="$erdAccount.address && innerWidth > 500"
            style="width: 95px"
          >
            {{ toShortAddress($erdAccount.obfuscatedAddress()) }}
          </div>
          <div v-else style="width: 40px">
            <font-awesome-icon :icon="['fas', 'wallet']" class="button_hover" />
          </div>
        </div>
        <!-- <span v-if="$erdAccount.token" class="menu__items-token"
            >Token : {{ $erd.token }}</span
          > -->
        <!-- </router-link> -->
      </div>
      <div v-show="!isSearchActive" id="theme" @click="toggleTheme()">
        <font-awesome-icon
          v-show="this.theme == ''"
          :icon="['fas', 'moon']"
          id="theme_moon"
          class="button_hover"
        />
        <font-awesome-icon
          v-show="this.theme == 'light'"
          :icon="['fas', 'sun']"
          id="theme_sun"
          class="button_hover"
        />
      </div>
    </div>
  </div>
  <div id="navbar-space"></div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCopyright,
  faSun,
  faMoon,
  faCaretDown,
  faMagnifyingGlass,
  faXmark,
  faBolt,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faCopyright,
  faSun,
  faMoon,
  faCaretDown,
  faMagnifyingGlass,
  faXmark,
  faBolt,
  faWallet
);

import "@/style/GlobalStyle.vue";
import "@/style/GlobalButtonStyle.vue";

export default {
  components: [],
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      innerWidth: (state) => state.innerWidth,
    }),
  },
  data() {
    return {
      isSearchActive: false,
    };
  },
  methods: {
    //...mapMutations("core", ["TOGGLE_THEME"]),
    toggleTheme() {
      //this.TOGGLE_THEME();
      this.$store.commit("TOGGLE_THEME");
      //this.$store.commit["core/TOGGLE_THEME"];
      //this.$store.commit("TOGGLE_THEME", "core", { module: "core" });
    },
    toShortAddress(address) {
      const last3characters = address.substring(address.length - 3);
      return `...${last3characters}`;
    },
    search() {
      if (this.isSearchActive === false) {
        this.isSearchActive = true;
        return;
      }

      console.log("search");
    },
  },
};
</script>

<style lang="scss">
#navbar-space {
  height: 66px;
}
#navbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 66px;
  background: var(--theme-color-1);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  box-shadow: 0 16px 24px rgb(0 0 0 / 4%);
  font-weight: 600;
}

#logo {
  height: 100%;
  height: 75px;
  margin-top: 4px;
}

#app_name {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 0px;
}

#wallet {
  overflow: hidden;
}

#theme {
  height: 100%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  --background-color: var(--theme-color-0);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: 0px;
}

#theme_sun {
  animation: rotation 44s linear infinite;

  transition: transform 0.44s;
  &:hover {
    transform: scale(1.5);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#menu_search {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  max-width: 500px;
  margin-top: 2.5px;
}
#menu_search_input {
  margin-right: 15px;
  width: 500px;
  max-width: calc(100vw - 100px);
  border: 2px solid var(--theme-color-2);
}
#menu_search_icon {
  width: 27.5px;
}
#menu_search_x {
  margin-right: 15px;
}

// #menu_dropdown {
//   display: flex;
//   justify-content: right;
//   align-content: center;
//   align-items: center;
// }
// #menu_dropdown_content {
//   position: absolute;
//   border: 1px solid var(--theme-color-2);
// }

#dropdown {
  // position: relative;
  // display: inline-block;
  --margin-left: 15px;
  width: 37.5px;
}
// #dropdown_button {
// }
/* Dropdown Content (Hidden by Default) */
#dropdown_content {
  display: none;
  position: absolute;
  width: 200px;
  margin-left: -100px;
  --background: var(--theme-color-1);
  --box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-top: 18.5px;
  z-index: 100;
}
/* Links inside the dropdown */
#dropdown_content a {
  background: var(--theme-color-1);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: var(--theme-color-2);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  margin-left: 1px solid var(--theme-color-2);
  margin-right: 1px solid var(--theme-color-2);
  margin-bottom: 1px solid var(--theme-color-2);
}
/* Change color of dropdown links on hover */
#dropdown_content a:hover {
  background-color: var(--theme-color-5);
  // transition: transform 0.44s;
  // &:hover {
  //   transform: scale(1.05);
  // }
}
/* Show the dropdown menu on hover */
#dropdown:hover #dropdown_content {
  display: block;
}
/* Change the background color of the dropdown button when the dropdown content is shown */
#dropdown:hover .dropdown_button {
  background-color: #3e8e41;
}

#menu_dropdown_items_wrapper {
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  flex-direction: column;

  padding: 5px;
  background: var(--theme-color-2) !important;
}
</style>
