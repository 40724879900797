export default {
  //namespaced: true,
  // state: {
  //   drops: [],
  // },
  // getters: {},
  // mutations: {
  //   //SET_USER_BALANCE(state, user_balance) {
  //   //  state.user_balance = user_balance;
  //   //},
  // },
  actions: {
    async getDrops() {
      return [
        {
          name: "STRAMOSI",
          tokenType: "NFT",
          dropDate: "04.11.2022",
          dropTime: "5:00 PM UTC",
          media: [
            "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/914.png",
            "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/915.png",
            "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/916.png",
          ],
          social: {
            website: "https://asdf.com",
            twitter: "https://twitter.com/@asdf",
            instagram: "https://instagram.com/@asdf",
            youtube: "asd",
            discord: "asd",
          },
          details: {
            dropType: "public sale",
            dropIndex: 1,
            totalDrops: 1,
            nftsToBeDropped: 10000,
            collectionNftsCount: 10000,
          },
          price: {},
        },
        {
          name: "APES",
          tokenType: "NFT",
          dropDate: "05.11.2022",
          dropTime: "4:20 PM UTC",
          media: [
            "https://media.elrond.com/nfts/asset/QmUBFTnxZpaM7xrJ62Z9kNi3dfQwEWPQhthsnXdLEjJhDb/9997.png",
            "https://media.elrond.com/nfts/asset/QmUBFTnxZpaM7xrJ62Z9kNi3dfQwEWPQhthsnXdLEjJhDb/9996.png",
            "https://media.elrond.com/nfts/asset/QmUBFTnxZpaM7xrJ62Z9kNi3dfQwEWPQhthsnXdLEjJhDb/9995.png",
            "https://media.elrond.com/nfts/asset/QmUBFTnxZpaM7xrJ62Z9kNi3dfQwEWPQhthsnXdLEjJhDb/9994.png",
          ],
          social: {
            website: "https://asdf.com",
            twitter: "https://twitter.com/@asdf",
            discord: "asd",
          },
          details: {
            dropType: "pre-sale",
            dropIndex: 1,
            totalDrops: 2,
            nftsToBeDropped: 200,
            collectionNftsCount: 10000,
          },
        },
        {
          name: "THE STRANGERS",
          tokenType: "SFT",
          dropDate: "22.12.2022",
          //dropTime: " 4:20 PM UTC",
          media: [
            "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/917.png",
          ],
          social: {
            website: "https://asdf.com",
            twitter: "https://twitter.com/@asdf",
            discord: "asd",
          },
          details: {
            dropType: "pre-sale",
            dropIndex: 1,
            totalDrops: 1,
            nftsToBeDropped: 1000,
            collectionNftsCount: 10000,
          },
        },
      ];
    },
  },
};
