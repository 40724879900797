<template>
  <swiper
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :modules="swiperModules"
    :autoplay="swiperAutoplay"
  >
    <swiper-slide v-for="(media, index) in trending"
      ><div class="nft-card">
        <img :src="media" class="image withFrame" />
        <!-- <div>erd.....123</div>
        <div>23.01.2021</div>
        <div>erd.....123</div>
        <a href="https://google.com">google</a> -->
      </div></swiper-slide
    >
  </swiper>
  <!-- <button @click="swiperSlideTo(1)">1</button> -->
</template>

<script>
// import Swiper core and required modules
import { A11y } from "swiper";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      this.swiper = swiper;
      //console.log(swiper);
    };
    const onSlideChange = () => {
      //console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [A11y],
    };
  },
  data() {
    return {
      swiper: null,
      swiperModules: [],
      swiperAutoplay: {
        delay: 12,
        disableOnInteraction: true,
      },
      //previewSwiper_activeSlide_index: null,
      trending: [
        "https://media.elrond.com/nfts/asset/QmRP9FVznm1xxa3FEsDENXhafRDaRWY6LKQdg8GygHNHKw/3023.png",
        "https://media.elrond.com/nfts/asset/QmUUhAmBQKGkSqN775NZAAYUaqd8ssMadFg2UYSECSERz6/1601.png",
        "https://media.elrond.com/nfts/asset/QmYpp86PLiHyAn4bFhXHzjcp4HP9QeUNqZKvvZ1ijsjKha/MaiarPunks%2302799.png",
      ],
    };
  },
  methods: {
    onSwiper(swiper) {
      //console.log(swiper);
      this.swiper = swiper;
    },
    onSlideChange() {
      console.log(`slide changed ${this.swiper.activeIndex}`);
    },
    swiperSlideTo(index) {
      try {
        this.swiper.slideTo(index);
      } catch (error) {
        // ignore
      }
    },
    onKeyDown(e) {
      switch (e.keyCode) {
        // <-
        case 37:
          this.swiperSlideTo(this.swiper.activeIndex - 1);
          break;
        // ->
        case 39:
          this.swiperSlideTo(this.swiper.activeIndex + 1);
          break;
      }
    },
  },
  created() {
    this.onKeyDown = this.onKeyDown.bind(this);
    document.addEventListener("keydown", this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
};
</script>

<style lang="scss">
.nft-card {
  margin-bottom: 50px;
}

.withFrame {
  margin: 10px auto;
  border: 20px solid var(--theme-color-1);
  -webkit-box-shadow: px 2px 3px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.3);
}

.image {
  object-fit: contain;
  --max-height: min(500px, 70vh);
  --max-width: min(500px, 90vw);
  max-width: min(500px, 90vw);
  height: 100%;
  width: 100%;
  border-radius: 4px;
}
</style>
