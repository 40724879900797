
import GalleryVue from "@/components/Gallery.vue";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "HomeView",
  components: { GalleryVue },
  computed: {
    ...mapState({
      theme: (state: any) => state.theme,
    }),
  },
});
