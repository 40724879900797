<template>
  <div id="footer">
    <div id="footer_text">
      <!-- <p>by artist, for artists</p> -->
      <p style="margin-right: 5px">copyright</p>
      ©
      <p style="margin-left: 5px">egld.art</p>
      <!-- <p>
        all rights reserved to <a href="https://www.elrond.com">elrond</a> and
        artists
      </p> -->
      <a
        id="footer_social"
        href="https://twitter.com/egldart"
        class="button_hover"
        ><font-awesome-icon alt="Twitter" :icon="['fab', 'twitter']"
      /></a>
    </div>

    <!-- <div id="footer_legal">
      <a href="">Terms & Conditions</a>
      <div class="space">·</div>
      <a href="">Privacy Policy</a>
      <div class="space">·</div>
      <a href="">Cookie Policy</a>
      <div class="space">·</div>
      <a href="">Security Policy</a>
    </div> -->
  </div>
</template>

<script scoped>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faDiscord,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faDiscord, faYoutube);

export default {
  setup() {},
};
</script>

<style lang="scss">
#footer {
  min-height: 66px;
  background: var(--theme-color-1);

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  box-shadow: 0 16px 24px rgb(0 0 0 / 4%);

  position: relative;
  bottom: 0;
}

@media (max-width: 500px) {
  #footer {
    flex-direction: column;
  }
}

#footer_text {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  min-height: 50px;

  & p,
  & p a {
    font-size: 12px;
  }
}

#footer_social {
  width: 50px;
}

#footer_legal {
  display: flex;
  //   flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  min-height: 50px;

  & a {
    font-size: 12px;
    word-wrap: break-word;
  }

  & .space {
    width: 10px;
  }
}
</style>
