import { IQRCodeHandler } from "vue-erdjs";
//import * as qrcodeOptions from "./qrcode-style";
import QRCodeStyling from "qr-code-styling";

require("../assets/egld-black.png");

const qrcodeOptions = {
  width: 300,
  height: 300,
  //image: "/vue-erdjs/egld-black.png",
  image: "",
  data: "https://www.egld.art", //"https://elrondtrees.com",
  type: "svg",
  margin: 0,
  qrOptions: {
    typeNumber: "0",
    mode: "Byte",
    errorCorrectionLevel: "Q",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.35,
    margin: 0,
  },
  dotsOptions: {
    type: "classy-rounded",
    color: "#3217B5",
  },
  backgroundOptions: {
    color: "#ffffff",
  },
  dotsOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#6a1a4c",
      color2: "#6a1a4c",
      rotation: "0",
    },
  },
  cornersSquareOptions: {
    type: "extra-rounded",
    color: "#3217B5",
  },
  cornersSquareOptionsHelper: {
    color: "#3217B5",
  },
  cornersDotOptions: {
    type: "",
    color: "#3217B5",
  },
  cornersDotOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  backgroundOptionsHelper: {
    colorType: {
      single: true,
      gradient: false,
    },
    gradient: {
      linear: true,
      radial: false,
      color1: "#ffffff",
      color2: "#ffffff",
      rotation: "0",
    },
  },
};

class CustomQRCodeHandler implements IQRCodeHandler {
  private qrcode = new QRCodeStyling(Object.assign(qrcodeOptions));

  handle(data: string, element: HTMLElement): Promise<any> {
    console.log("Handle", data, element);
    qrcodeOptions.data = data;
    this.qrcode.update(Object.assign(qrcodeOptions));
    this.qrcode.append(element);
    return Promise.resolve(undefined);
  }
}

export default CustomQRCodeHandler;
