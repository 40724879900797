import { VueErdjsConnect } from "vue-erdjs";
import VueErdJs from "vue-erdjs/types/VueErdJs";
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import home from "../views/home.vue";
import CustomQRCodeHandler from "./CustomQRCodeHandler";
import Profile from "../components/Profile.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/profile",
    alias: ["/auth"],
    name: "Profile",
    component: Profile,
    props: { qrcodeHandler: new CustomQRCodeHandler(), token: "hello" },
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/authenticate",
    name: "VueErdjsConnect",
    component: VueErdjsConnect,
    props: { qrcodeHandler: new CustomQRCodeHandler(), token: "hello" },
  },
  {
    path: "/drops",
    name: "Drops",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/drops.vue"),
  },
  {
    path: "/create-drop",
    name: "create drop",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/create-drop.vue"),
    meta: {
      //requiresAuth: true,
      //customAuth: true,
    },
  },
  // NOT FOUND
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// createWebHistory or createWebHashHistory

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  const requiresCustomAuth = to.matched.some(
    (record) => record.meta.customAuth
  );
  const customAuthVerified =
    from.fullPath === "/profile" || from.fullPath === "/auth";

  // console.log(JSON.stringify(to));
  // console.log(JSON.stringify(from));
  if (!requiresAuth) {
    // custom auth :(
    // if (requiresCustomAuth) {
    //   if (!customAuthVerified) {
    //     next({
    //       path: "/auth",
    //       query: { to: to.fullPath },
    //     });
    //   }
    //   next();
    // } else {
    //   next();
    // }
    next();
  } else if (true) {
    //!vueErdJsStore.logged) {
    next({
      path: "/authenticate",
      query: { fromUrl: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
